import useSWRImmutable from "swr/immutable";
import { hc } from "hono/client";
import { ProductDetailsRoute } from "../../../routes/_apis/products";
import { cacheFetch } from "../../../libs/client/fetch";
import { createContext, FC, ReactNode, useContext } from "react";

const baseUrl = new URL("https://api.survaq.com/products/");
if (import.meta.env.DEV) {
  baseUrl.protocol = "http:";
  baseUrl.hostname = "localhost";
  baseUrl.port = "8000";
}
const client = hc<ProductDetailsRoute>(baseUrl.toString(), { fetch: cacheFetch });

const useProductData = (productId: string) => {
  const { data } = useSWRImmutable(
    productId,
    async (key) => {
      const res = await client[":id"].$get({ param: { id: key } });
      return res.json();
    },
    {
      errorRetryInterval: 3000,
      shouldRetryOnError: true,
    },
  );

  return data;
};

export type Product = Exclude<ReturnType<typeof useProductData>, undefined>;

const Context = createContext<Product | undefined>(undefined);

export const ProductProvider: FC<{ productId: string; children: ReactNode }> = ({
  productId,
  children,
}) => {
  const product = useProductData(productId);
  if (!product) return null;
  return <Context.Provider value={product}>{children}</Context.Provider>;
};

export const useProduct = () => {
  const product = useContext(Context);
  if (!product) throw new Error("ProductProvider is not found");
  return product;
};

import { FC, useEffect } from "react";
import { useProduct } from "./ProductProvider";

export const FuncReplaceScheduleText: FC = () => {
  const product = useProduct();

  useEffect(() => {
    Array.from(document.querySelectorAll<HTMLSpanElement>(".delivery-schedule")).forEach((t) => {
      const short = !!t.dataset["short"];
      t.innerText =
        (short ? product.schedule?.text.replace(/(\d{4}|年)/g, "") : product.schedule?.text) ?? "";
    });
  }, []);

  return null;
};

import React, { FC, useMemo } from "react";
import { SkuSelector } from "./SkuSelector";
import { useVariantId } from "./hooks/useVariantId";
import { ScheduleCaution } from "./ScheduleCaution";
import { useSelectVariantAndSKUs } from "../common/hooks/useSelectVariantAndSKUs";
import { useProduct } from "../common/ProductProvider";

type Props = {
  initialVariantId: string;
};

export const ShopifyAdditionalSelectors: FC<Props> = ({ initialVariantId }) => {
  const product = useProduct();
  const { selectedSkus, baseSkus, schedule, changeVariant, selectSKU, selectableSkuGroups } =
    useSelectVariantAndSKUs(product, initialVariantId);

  const variantId = useVariantId(initialVariantId);
  useMemo(() => changeVariant(variantId), [variantId]);

  return (
    <div>
      {selectableSkuGroups.map(({ description, blockName, selects }, index1) => (
        <div key={index1} className="product-form__sku-group-block">
          {blockName && <div className="product-form__sku-group-block__name">{blockName}</div>}
          {description && (
            <div className="product-form__sku-group-block__description">{description}</div>
          )}
          {selects.map(({ label, selected, options }, index2) => (
            <SkuSelector
              label={label}
              selected={selected}
              options={options}
              onChange={(value) => selectSKU(index1, index2, value)}
              key={index2}
            />
          ))}
        </div>
      ))}
      {[...selectedSkus.flat(), ...baseSkus].map((sku, index) => (
        <input key={index} name={`properties[_sku${index + 1}]`} type="hidden" value={sku} />
      ))}
      {schedule &&
        product.schedule &&
        schedule.numeric > product.schedule.numeric &&
        document.documentElement.lang === "ja" && (
          <ScheduleCaution
            message={
              <>
                &quot;配送予定：{schedule.text.replace(/(\d{4}|年)/g, "")}
                &quot;の商品が含まれております。
                <br />
                ※2点以上ご注文の場合、全て揃った時点でまとめて発送
              </>
            }
          />
        )}
    </div>
  );
};

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.extend(isBetween);

const terms = {
  early: 0,
  middle: 1,
  late: 2,
} as const;

export type Schedule = {
  numeric: number;
  text: string;
  subText?: string;
};

export type Locale = "ja" | "en";

const schedule = (
  year: string | number,
  month: string | number,
  term: keyof typeof terms,
  locale: Locale,
): Schedule => {
  const text = createScheduleText({ year, month, term }, locale);
  return {
    numeric: Number(`${year}${String(month).padStart(2, "0")}${terms[term]}`),
    text,
    subText: monthWithDateRange(Number(year), Number(month) - 1, term, locale),
  };
};

const createScheduleText = (
  {
    year,
    month,
    term = "late",
  }: {
    year: string | number;
    month: string | number;
    term?: keyof typeof terms;
  },
  locale: "ja" | "en" = "ja",
): string => {
  const date = dayjs(`${year}-${month}-${term === "late" ? 28 : term === "middle" ? 18 : 8}`);
  return yearMonthTerm(date.year(), date.month(), date.date(), locale);
};

const yearMonthTerm = (
  year: number,
  monthIndex: number,
  date: number,
  locale: "ja" | "en" = "ja",
) => {
  if (locale === "ja")
    return `${year}年${monthIndex + 1}月${date > 20 ? "下旬" : date > 10 ? "中旬" : "上旬"}`;

  return `${date > 20 ? "late" : date > 10 ? "mid" : "early"} ${months[monthIndex]}. ${year}`;
};

const monthWithDateRange = (
  year: number,
  monthIndex: number,
  term: keyof typeof terms,
  locale: "ja" | "en" = "ja",
) => {
  const month = monthIndex + 1;
  const daysInMonth = dayjs(new Date(year, monthIndex, 1)).daysInMonth();
  const [begin, end] = [
    term === "early" ? 1 : term === "middle" ? 11 : 21,
    term === "early" ? 10 : term === "middle" ? 20 : daysInMonth,
  ];
  if (locale === "ja") return `${month}/${begin}〜${month}/${end}`;

  return `${months[monthIndex]}. ${begin} - ${end}`;
};

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const makeCurrentSchedule = (locale: Locale = "ja", displayBySeason = true) => {
  // 28~7=early, 8~17=middle, 18~27=lateなので、4日後の日付を基準にすると年・月またぎも含めてスムーズに計算できる
  const date = dayjs().tz().add(4, "day");
  const year = date.year();
  const month = date.month() + 1;
  const day = date.date();

  const term: keyof typeof terms = day <= 11 ? "early" : day <= 21 ? "middle" : "late";

  const res = schedule(year, month, term, locale);
  if (!displayBySeason) {
    res.text = locale === "ja" ? "3営業日以内" : "Within 7 business days";
    res.subText = undefined;
    res.numeric = 0;
  }

  return res;
};

export const makeSchedule = (scheduleCode: string, locale: Locale = "ja"): Schedule => {
  const [year, month, term] = scheduleCode.split("-") as [string, string, keyof typeof terms];
  const scheduleMadeByCustom = schedule(year, month, term, locale);

  const current = makeCurrentSchedule(locale);
  return scheduleMadeByCustom.numeric > current.numeric ? scheduleMadeByCustom : current;
};

export const makeScheduleByNumeric = (numeric: number, locale: Locale = "ja"): Schedule => {
  if (numeric === 0) return makeCurrentSchedule(locale, false);
  const year = String(numeric).slice(0, 4);
  const month = String(numeric).slice(4, 6);
  const term = Object.entries(terms).find(
    ([, v]) => v === Number(String(numeric).slice(6, 7)),
  )?.[0] as keyof typeof terms | undefined;
  if (!term) throw new Error(`Invalid schedule numeric: ${numeric}`);

  return schedule(year, month, term, locale);
};

export const makeScheduleCode = (schedule: Schedule | null) => {
  if (!schedule || schedule.numeric === 0) return null;
  const year = String(schedule.numeric).slice(0, 4);
  const month = String(schedule.numeric).slice(4, 6);
  const termNum = String(schedule.numeric).slice(6, 7);
  const term = Object.entries(terms).find(([, v]) => v === Number(termNum))?.[0];
  return `${year}-${month}-${term}` as const;
};

// 引数が過去日で構成される場合、過去日が最遅として計算されてしまうので、それを防ぎたい場合にはmakeSchedule(null)で最短配送日をガードとして入れないといけないことに注意
// ※ makeCurrentSchedule|makeScheduleによって生成されたスケジュールは過去日は含まれないのでガード不要
export const latest = (schedules: Array<null | Schedule>) => {
  return (
    schedules.sort((a, b) => {
      const current = makeCurrentSchedule();
      const l = a?.numeric ?? current.numeric;
      const r = b?.numeric ?? current.numeric;
      return l > r ? -1 : l < r ? 1 : 0;
    })[0] ?? null
  );
};

export const earliest = (schedules: Array<null | Schedule>) => {
  return (
    schedules.sort((a, b) => {
      const l = a?.numeric ?? 0;
      const r = b?.numeric ?? 0;
      return l > r ? 1 : l < r ? -1 : 0;
    })[0] ?? null
  );
};

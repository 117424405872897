import { useMemo, useReducer } from "react";
import { latest } from "../../../../libs/makeSchedule";
import { produce } from "immer";
import { Product } from "../ProductProvider";

type Variant = Product["variants"][number];

type SelectableSkuGroup = {
  blockName: string | null;
  description: string | null;
  selects: Array<{
    label: string;
    selected: { code: string; name: string } | null;
    options: Array<{ code: string; name: string }>;
  }>;
};

export const useSelectVariantAndSKUs = (product: Product, initialVariantId?: string) => {
  const [{ selectedSkus, baseSkus, variant }, dispatch] = useReducer(
    makeReducer(product),
    initialReducer(product, initialVariantId),
  );

  const schedule = latest([
    product.schedule,
    variant?.schedule ?? null,
    ...baseSkus.map((code) => getSkuByCode(product, code).schedule),
    ...selectedSkus.flatMap((codes) => codes.map((code) => getSkuByCode(product, code).schedule)),
  ]);

  const changeVariant = (variantId: string) =>
    dispatch({ type: "changeVariant", payload: { variantId } });

  const selectSKU = (index1: number, index2: number, value: string) =>
    dispatch({ type: "selectedSKU", payload: { index1, index2, value } });

  const selectableSkuGroups = useMemo<Array<SelectableSkuGroup>>(() => {
    return (
      variant?.additionalSelects.map((item, index1) => {
        return {
          ...item,
          selects: item.selects.map((select, index2) => {
            const selectedCode = selectedSkus[index1]?.[index2] ?? null;
            const selected = selectedCode
              ? (select.options.find((option) => option.code === selectedCode) ?? null)
              : null;
            return {
              selected,
              ...select,
            };
          }),
        };
      }) ?? []
    );
  }, [variant, product, selectedSkus]);

  return {
    selectedSkus,
    baseSkus,
    selectSKU,
    changeVariant,
    variant,
    schedule,
    selectableSkuGroups,
  };
};

const getVariantByVariantId = (product: Product, variantId: string) => {
  const variant = product.variants.find((v) => v.id === variantId);
  if (!variant)
    throw new Error(`variant not found (product: ${product.id}, variant: ${variantId})`);
  return variant;
};

const getSkuByCode = (product: Product, code: string) => {
  const sku = product.skus.find((s) => s.code === code);
  if (!sku) throw new Error(`sku not found (product: ${product.id}, sku: ${code})`);
  return sku;
};

type Actions =
  | { type: "selectedSKU"; payload: { index1: number; index2: number; value: string } }
  | { type: "changeVariant"; payload: { variantId?: string } };

type State = { selectedSkus: string[][]; baseSkus: string[]; variant: Variant | null };

const initialReducer = (product: Product, variantId?: string): State => {
  const variant = variantId ? getVariantByVariantId(product, variantId) : null;
  return {
    selectedSkus:
      variant?.additionalSelects.map(({ selects }) => {
        return selects.flatMap((select) => select.options[0]?.code ?? []);
      }) ?? [],
    baseSkus: variant?.baseSkuCodes ?? [],
    variant,
  };
};

const makeReducer =
  (product: Product) =>
  (state: State, action: Actions): State => {
    if (action.type === "selectedSKU") {
      const { index1, index2 } = action.payload;
      return produce(state, (draft) => {
        draft.selectedSkus[index1]![index2] = action.payload.value;
      });
    } else if (action.type === "changeVariant") {
      if (!action.payload.variantId) return { baseSkus: [], selectedSkus: [], variant: null };

      const variant = getVariantByVariantId(product, action.payload.variantId);
      const selectedSkus = variant.additionalSelects.map(({ selects }, index1) => {
        return selects.flatMap((item, index2) => {
          const selectedCode = state.selectedSkus[index1]?.[index2];
          // すでに選択されているSKUが新しいバリアントのSKUに含まれている場合はそのSKUを維持する
          if (selectedCode && item.options.some((option) => option.code === selectedCode))
            return selectedCode;
          return item.options[0]?.code ?? [];
        });
      });

      return { selectedSkus, baseSkus: variant.baseSkuCodes, variant };
    }

    return state;
  };

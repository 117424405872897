import { useEffect } from "react";

const sentEvents = new Set<string>();

const handleScroll = () => {
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = document.documentElement.clientHeight;
  const scrollTop = document.documentElement.scrollTop;
  const percentage = Math.floor((scrollTop / (scrollHeight - clientHeight)) * 100);

  if (percentage % 10 === 0 && !sentEvents.has(`scroll-${percentage}`)) {
    // @ts-ignore
    window.gtag?.("event", "scroll", {
      event_category: "Scroll Depth",
      event_label: `${percentage}%`,
      value: percentage,
      non_interaction: true,
    });
    sentEvents.add(`scroll-${percentage}`);
  }
};

const handleFormFocus = (event: WindowEventMap["focusin"]) => {
  if (!event.target || !("closest" in event.target) || typeof event.target.closest !== "function")
    return;

  const form = event.target.closest("form");
  if (!form) return;
  const formId = form.getAttribute("id") || "no-id";
  if (!sentEvents.has(`focus-${formId}`)) {
    // @ts-ignore
    window.gtag?.("event", "form_focus", {
      event_category: "Form Focused",
      event_label: formId,
      value: 1,
    });
    sentEvents.add(`focus-${formId}`);
  }
};

export const Tracking = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("focusin", handleFormFocus);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("focusin", handleFormFocus);
    };
  });

  return null;
};

import React from "react";
import { createRoot } from "react-dom/client";
import { ShopifyAdditionalSelectors } from "../components/react/shopify/ShopifyAdditionalSelectors";
import { FuncReplaceScheduleText } from "../components/react/common/FuncReplaceScheduleText";
import { Tracking } from "../components/react/common/Tracking";
import * as Sentry from "@sentry/react";
import { ProductProvider } from "../components/react/common/ProductProvider";
import { SWRConfig } from "swr";

const main = (productId: string, initialVariantId: string) => {
  Sentry.init({
    dsn: "https://917da1f9069cfa51c05038eb3e470b59@o4508453707841536.ingest.us.sentry.io/4508453710594048",
    integrations: [],
    beforeSend: (event) => {
      console.log(event.exception?.values);
      const shouldTrace = event.exception?.values?.some((v) => {
        return v.stacktrace?.frames?.some(
          (f) => f.filename?.includes("api.survaq.com") && !f.filename?.includes("@sentry/core"),
        );
      });

      if (shouldTrace) return event;
      return null;
    },
  });

  const domNode = document.getElementById("additionalProperties");
  if (domNode) {
    const root = createRoot(domNode);
    root.render(
      <SWRConfig
        value={{
          onError: (err) => {
            Sentry.captureException(err);
          },
        }}
      >
        <ProductProvider productId={productId}>
          <ShopifyAdditionalSelectors initialVariantId={initialVariantId} />
          <FuncReplaceScheduleText />
          <Tracking />
        </ProductProvider>
      </SWRConfig>,
    );
  }
};

// @ts-ignore
window.customScriptSurvaq = main;
